import React, {useEffect, useRef, useState} from "react"

import Layout from "src/components/layout"
import Head from "../../components/Head"

import Start from "src/components/Start/Start"
import Tariffs from "src/components/tariffs/Tariffs/Tariffs"
import Button from "../../components/common/Button/Button"

import "../index.scss"
import { useTranslation } from "react-i18next"
import { graphql, useStaticQuery } from "gatsby"
import COUNTRY from "../../constants";
import BRANDS from "../../brands"

const ClientsMain = ({ location }) => {
  const data = useStaticQuery(graphql`
    query pricesPageClientsBy {
      site {
        siteMetadata {
          senderPath
          countries {
            by {
              hotLine {
                tel
                title
              }
              statistics {
                supplier {
                  subscription_amount_array {
                    key
                    value
                  }
                  city_amount_array {
                    key
                    value
                  }
                }
                activity {
                  subscription_amount_array {
                    key
                    value
                  }
                  city_amount_array {
                    key
                    value
                  }
                }
                suppliers {
                  id
                  name
                  city
                  address
                  subscription
                }
                cities {
                  key
                  value
                }
                activities_subscriptions {
                  key
                  name
                  subscription
                  city
                }
                activities_byCities {
                  city
                  activities {
                    name
                    subscription
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const { t,i18n } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage(COUNTRY.BY)
  }, [i18n])

  const statistics = data.site.siteMetadata.countries.by.statistics

  const startRef = useRef()
  const [cardType, setCardType] = useState(undefined)
  const [email, setEmail] = useState("")
  const emailDisabled = false
  const [showForm, setShowForm] = useState(false)
  const [, setForm] = useState({
    company: "",
  })
  const sendForm = (form) => {
    setForm(form)
    return fetch(data.site.siteMetadata.senderPath, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    })
      .then((response) => {
        alert(t("sendFormAlert"))
      })
      .catch((e) => {
        console.error(e)
      })
  }
  function goToForm(name) {
    setCardType(name)
    setShowForm(true)
    if (startRef.current) {
      startRef.current.scrollIntoView()
    }
  }
  let block = (
    <section className="companies-discount">
      <h3>{t("prices.companiesDiscount.title")}</h3>
      <Button color="white" onClick={goToForm}>
        {t("prices.companiesDiscount.buttonTitle")}
      </Button>
    </section>
  )
  if (showForm) {
    block = (
      <Start
        type="tariffs"
        cardType={cardType}
        email={email}
        emailDisabled={emailDisabled}
        setEmail={setEmail}
        setForm={sendForm}
      />
    )
  }
  return (
    <Layout
      hotLine={data.site.siteMetadata.countries.by.hotLine}
      location={location}
      country="by"
    >
      <Head
        lang="by"
        title={t('head.prices.title', { brand: BRANDS.ALLSPORTS.brand })}
        description={t('head.prices.description', { brand: BRANDS.ALLSPORTS.brand })}
        slug="/prices/"
      />
      <Tariffs
        titleRu={t("prices.tariffsTitle")}
        goToForm={goToForm}
        statistics={statistics}
        statusOpenModal={location.hash.substr(1)}
        country={COUNTRY.BY}
      />
      <section className="fullscreen" ref={startRef}>
        {block}
      </section>
    </Layout>
  )
}

export default ClientsMain
